import client from "libs/client";

const getEnumsByTipoEnum = async (data) => {
  let response = await client("POST", data, "/admin/enums");
  return response;
};

const getDetailTest = async (data) => {
  let response = await client("POST", data, "/admin/prueba/all");
  return response;
};

const crearPregunta = async (data) => {
  let response = await client("POST", data, "/admin/pregunta");
  return response;
};

const actualizarPregunta = async (data) => {
  let response = await client("POST", data, "/admin/pregunta");
  return response;
};

const eliminarPregunta = async (prueba, pregunta) => {
  let response = await client("DELETE", {}, `/admin/pregunta/${prueba}/${pregunta}`);
  return response;
};

const crearRespuesta = async (data) => {
  let response = await client("POST", data, "/admin/respuesta");
  return response;
};

const actualizarRespuesta = async (data) => {
  let response = await client("POST", data, "/admin/respuesta");
  return response;
};

const eliminarRespuesta = async (prueba, pregunta, respuesta) => {
  let response = await client("DELETE", {}, `/admin/respuesta/${prueba}/${pregunta}/${respuesta}`);
  return response;
};

const obtenerModeloEvaluador = async (data) => {
  let response = await client("POST", data, "/admin/models/15");
  return response;
};

const obtenerModeloDocente = async (data) => {
  let response = await client("POST", data, "/admin/models/9");
  return response;
};


const adminService = {
  getEnumsByTipoEnum,
  getDetailTest,
  crearPregunta,
  actualizarPregunta,
  eliminarPregunta,
  crearRespuesta,
  actualizarRespuesta,
  eliminarRespuesta,
  obtenerModeloEvaluador,
  obtenerModeloDocente
};

export default adminService;